import React from "react";
import ContentContainer from "../../../../components/container/ContentContainer";
import ContactExpert from "../../../../components/controls/ContactExpert";
import TitleDiv from "../../../../components/controls/TitleDiv";
import Header from "../../../../components/sections/Header";
import HeadData from "../../../../data/HeadData";
import { useStaticQuery, graphql } from "gatsby";
import SimpleReactLightbox from "simple-react-lightbox";
import Thumbnail from "../../../../components/controls/Thumbnail";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import Layout from "../../../../components/layout/Layout";

const LegnoPage = () => {
  const path = ["Textured Melamine in Southern California", "The Legno Collection"];

  const gallery = useStaticQuery(graphql`
    {
      legno: file(
        relativePath: { eq: "textured-melamine/legno-collection.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      legno2: file(
        relativePath: { eq: "textured-melamine/textured-melamine-1_kttghh.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      walnutPittoniThumb: allFile(
        filter: {
          relativeDirectory: { eq: "textured-melamine/legno/walnut-pittoni" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      walnutPittoniHiRes: allFile(
        filter: {
          relativeDirectory: {
            eq: "textured-melamine/legno/walnut-pittoni/hi-res"
          }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
              )
            }
          }
        }
      }

      mezzoCherryThumb: allFile(
        filter: {
          relativeDirectory: { eq: "textured-melamine/legno/mezzo-cherry" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                formats: [AUTO, WEBP]
                quality: 100
              )
            }
          }
        }
      }
      mezzoCherryHiRes: allFile(
        filter: {
          relativeDirectory: {
            eq: "textured-melamine/legno/mezzo-cherry/hi-res"
          }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
              )
            }
          }
        }
      }

      trentoOakThumb: allFile(
        filter: {
          relativeDirectory: { eq: "textured-melamine/legno/trento-oak" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      trentoOakHiRes: allFile(
        filter: {
          relativeDirectory: { eq: "textured-melamine/legno/trento-oak/hi-res" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
              )
            }
          }
        }
      }

      tokajAlderThumb: allFile(
        filter: {
          relativeDirectory: { eq: "textured-melamine/legno/tokaj-alder" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      tokajAlderHiRes: allFile(
        filter: {
          relativeDirectory: {
            eq: "textured-melamine/legno/tokaj-alder/hi-res"
          }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
              )
            }
          }
        }
      }

      ashlandThumb: allFile(
        filter: { relativeDirectory: { eq: "textured-melamine/legno/ashland" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      ashlandHiRes: allFile(
        filter: {
          relativeDirectory: { eq: "textured-melamine/legno/ashland/hi-res" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
              )
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData 
          title="The Legno Collection" 
          description="Legno’s realistic textured wood grain patterns and texture-matched grains are the ideal complements for your designs."
        />
        <Header />
        <TitleDiv
          parentText="Products"
          title="The Legno Collection"
          className="mb-14"
          path={path}
        />

        <ContentContainer className="bg-gray5 text-center py-12">
          <h2>Collection</h2>
        </ContentContainer>
        <ContentContainer className="bg-gray2 py-12 mb-9">
          <div className="flex flex-col text-center items-center md:px-20">
            <p className="text-3xl">The Legno Collection</p>
            <hr className="border-blue1 border-t-2 mt-4 w-56" />
            <div className="flex flex-col lg:flex-row flex-wrap w-full pt-14 gap-x-0 gap-y-4 sm:gap-y-11">
              <BackgroundImage
                className="w-full min-h-52 lg:w-1/2 bg-left-top"
                {...convertToBgImage(getImage(gallery.legno))}
              />
              <BackgroundImage
                className="flex md:hidden w-full h-52"
                {...convertToBgImage(getImage(gallery.legno2))}
              >
                &nbsp;
              </BackgroundImage>
              <div className="w-full lg:w-1/2 md:bg-gray1 py-10 md:py-20 px-0 md:px-12 text-left">
                <p>
                  Legno’s realistic textured wood grain patterns and
                  texture-matched grains are the ideal complements for your
                  designs. Whether you are creating a contemporary rustic feel or
                  a more modern style, you will appreciate our affordable and
                  durable wood alternative. StevensWood® only uses materials that
                  are 100% recycled and recovered, or GRN+ non-added formaldehyde
                  core materials. Available in 5’ x 8’ panels. Edge banding also
                  available.
                </p>
              </div>
              <BackgroundImage
                className="hidden md:flex w-full h-80"
                {...convertToBgImage(getImage(gallery.legno2))}
              >
                &nbsp;
              </BackgroundImage>
            </div>
          </div>
        </ContentContainer>

        <ContentContainer className="bg-gray5 py-7">
          <p className="text-3xl font-bold font-raleway md:px-20 text-center md:text-left">Walnut Pittoni</p>
        </ContentContainer>
        <ContentContainer className="md:bg-gray2 mb-9">
          <div className="flex flex-col-reverse md:flex-row py-4 md:py-14 md:px-20">
            <SimpleReactLightbox>
              <div className="flex-grow grid grid-cols-3 gap-x-8 gap-y-8 text-center items-start">
                {gallery.walnutPittoniThumb.edges.map((thumbnail, index) => {
                  return (
                    <Thumbnail
                      key={`gallery${index}`}
                      hiRes={
                        gallery.walnutPittoniHiRes.edges[index].node
                          .childImageSharp.gatsbyImageData.images.fallback.src
                      }
                      name={thumbnail.node.name}
                      thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                    />
                  );
                })}
              </div>
            </SimpleReactLightbox>
            <div className="w-full md:w-1/2 p-10 xl:p-44 text-xl font-medium flex items-center justify-center text-center sm:text-left">
              <p>classic wood grains coupled with an aggressive depth</p>
            </div>
          </div>
        </ContentContainer>

        <ContentContainer className="bg-gray5 py-7">
          <p className="text-3xl font-bold font-raleway md:px-20 text-center md:text-left">Mezzo Cherry</p>
        </ContentContainer>
        <ContentContainer className="bg-gray2 mb-9 ">
          <div className="flex flex-col md:flex-row py-4 md:py-14 md:px-20">
            <div className="w-full md:w-1/2 p-10 xl:p-44 text-xl font-medium flex justify-center items-center text-center sm:text-left">
              clean and modern with a fresh complementing look
            </div>
            <SimpleReactLightbox>
              <div className="grid grid-cols-3 gap-x-8 gap-y-8 text-center items-start">
                {gallery.mezzoCherryThumb.edges.map((thumbnail, index) => {
                  return (
                    <Thumbnail
                      key={`gallery${index}`}
                      hiRes={
                        gallery.mezzoCherryHiRes.edges[index].node.childImageSharp
                          .gatsbyImageData.images.fallback.src
                      }
                      name={thumbnail.node.name}
                      thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                    />
                  );
                })}
              </div>
            </SimpleReactLightbox>
          </div>
        </ContentContainer>

        <ContentContainer className="bg-gray5 py-7">
          <p className="text-3xl font-bold font-raleway md:px-20 text-center md:text-left">Trento Oak</p>
        </ContentContainer>
        <ContentContainer className="bg-gray2 mb-9">
          <div className="flex flex-col-reverse md:flex-row py-4 md:py-14 md:px-20">
            <SimpleReactLightbox>
              <div className="grid grid-cols-3 gap-x-8 gap-y-8 text-center items-start">
                {gallery.trentoOakThumb.edges.map((thumbnail, index) => {
                  return (
                    <Thumbnail
                      key={`gallery${index}`}
                      hiRes={
                        gallery.trentoOakHiRes.edges[index].node.childImageSharp
                          .gatsbyImageData.images.fallback.src
                      }
                      name={thumbnail.node.name}
                      thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                    />
                  );
                })}
              </div>
            </SimpleReactLightbox>
            <div className="w-full md:w-1/2 p-10 xl:p-44 text-xl font-medium flex items-center text-center sm:text-left">
              distressed aesthetics and bold cathedrals
            </div>
          </div>
        </ContentContainer>

        <ContentContainer className="bg-gray5 py-7">
          <p className="text-3xl font-bold font-raleway md:px-20 text-center md:text-left">Tokaj Alder</p>
        </ContentContainer>
        <ContentContainer className="bg-gray2 mb-9 ">
          <div className="flex flex-col md:flex-row py-4 md:py-14 sm:px-20 items-center justify-center">
            <div className="w-full md:w-1/2 p-10 xl:p-44 text-xl font-medium text-center sm:text-left">
              Tighter full cathedrals, rustic yet clean
            </div>
            <SimpleReactLightbox>
              <div className="grid grid-cols-3 gap-x-8 gap-y-8 text-center items-start">
                {gallery.tokajAlderThumb.edges.map((thumbnail, index) => {
                  return (
                    <Thumbnail
                      key={`gallery${index}`}
                      hiRes={
                        gallery.tokajAlderHiRes.edges[index].node.childImageSharp
                          .gatsbyImageData.images.fallback.src
                      }
                      name={thumbnail.node.name}
                      thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                    />
                  );
                })}
              </div>
            </SimpleReactLightbox>
          </div>
        </ContentContainer>

        <ContentContainer className="bg-gray5 py-7">
          <p className="text-3xl font-bold font-raleway md:px-20 text-center md:text-left">Ashland</p>
        </ContentContainer>
        <ContentContainer className="bg-gray2 mb-9">
          <div className="flex flex-col-reverse md:flex-row py-4 md:py-14 md:px-20">
            <SimpleReactLightbox>
              <div className="grid grid-cols-3 gap-x-8 gap-y-8 text-center items-start">
                {gallery.ashlandThumb.edges.map((thumbnail, index) => {
                  return (
                    <Thumbnail
                      key={`gallery${index}`}
                      hiRes={
                        gallery.ashlandHiRes.edges[index].node.childImageSharp
                          .gatsbyImageData.images.fallback.src
                      }
                      name={thumbnail.node.name}
                      thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                    />
                  );
                })}
              </div>
            </SimpleReactLightbox>
            <div className="w-full md:w-1/2 p-10 xl:p-44 flex items-center text-xl font-medium text-center sm:text-left">
              strolls in with warmth and charm, inviting you to sit and stay
              awhile, featuring appealing colors and distinctive textures
            </div>
          </div>
        </ContentContainer>

        <ContactExpert />
      </div>
    </Layout>
  );
};

export default LegnoPage;
